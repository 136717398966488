//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

// Font
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  background-color: $diagonal-background-1;
  font-family: $open-sans;
}

p {
  color: $dark;
  font-size: 1.1em;
  font-weight: 400;
}

.lead {
  font-size: 1.1em;
  font-weight: 400;
}

.bg-dark p {
  color: $light;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-sans-serif;
  font-weight: 400;
}

h1,
.h1 {
  font-family: $font-family-sans-serif;
  font-size: 90px;
  @media screen and (min-width: map-get($grid-breakpoints, "md")) {
    font-size: 120px;
  }
}

h2,
.h2 {
  font-family: $font-family-sans-serif;
  font-size: 35px;
  font-weight: 400;
  @media screen and (min-width: map-get($grid-breakpoints, "md")) {
    font-size: 65px;
  }
}

h3,
.h3 {
  font-family: $font-family-sans-serif;
  font-size: 18px;
  @media screen and (min-width: map-get($grid-breakpoints, "md")) {
    font-size: 30px;
  }
}

h4,
.h4 {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  line-height: 1.25em;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
}

.font-alt-1 {
  font-family: $font-family-sans-serif;

  font-size: 20px;
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    font-size: 30px;
  }

  text-transform: initial;
  line-height: 1.3em;
}

.accordion {
  font-family: $open-sans;
}

@each $color, $value in $colors {
  .text-#{$color} {
    color: $value !important;
  }
  a .text-#{$color} {
    @include hover-focus {
      color: darken($value, 10%) !important;
    }
  }
}

@each $color, $value in $colors {
  .text-#{$color} {
    color: $value !important;
  }
  a .text-#{$color} {
    @include hover-focus {
      color: darken($value, 10%) !important;
    }
  }
}
.text-black {
  color: $black !important;
}
a .text-black {
  @include hover-focus {
    color: darken($black, 10%) !important;
  }
}

@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

@each $color, $value in $colors {
  .underline-#{$color} {
    text-decoration: underline;
    text-decoration-color: $value;
  }
}

// Bg divs
.bg-transparent {
  background-color: $transparent !important;
}

.bg-black {
  background-color: $black !important;
}

@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

// Circles
.sectors-circle-1,
.sectors-circle-2,
.sectors-circle-3,
.sectors-circle-4,
.sectors-circle-5,
.sectors-circle-6 {
  width: 20vw;
  height: 20vw;
  min-width: 240px;
  min-height: 240px;
  max-height: 280px;
  max-width: 280px;

  border-radius: 50%;
  margin-bottom: 0.8em;
  -moz-box-shadow: inset 0 3px 15px #00000066, 0 3px 15px #00000066;
  -webkit-box-shadow: inset 0 3px 15px #00000066, 0 3px 15px #00000066;
  box-shadow: inset 0px 3px 15px #00000066, 0 3px 15px #00000066;

  display: flex;
  align-items: center;
  justify-content: center;
}

.sectors-circle-1 > h4,
.sectors-circle-2 > h4,
.sectors-circle-3 > h4,
.sectors-circle-4 > h4,
.sectors-circle-5 > h4,
.sectors-circle-6 > h4 {
  font-size: 1.75em !important;
  padding: 0.5em;
}

.sectors-circle-1 {
  background: #a7abab;
  border: $sector-circle-border-width solid #9ebdd6;
}
.sectors-circle-2 {
  background: #9ebdd6;
  border: $sector-circle-border-width solid #0e498b;
}
.sectors-circle-3 {
  background: #0e498b;
  border: $sector-circle-border-width solid #a7abab;
}
.sectors-circle-4 {
  background: #9ebdd6;
  border: $sector-circle-border-width solid #a7abab;
}
.sectors-circle-5 {
  background: #0e498b;
  border: $sector-circle-border-width solid #9ebdd6;
}
.sectors-circle-6 {
  background: #a7abab;
  border: $sector-circle-border-width solid #0e498b;
}

// Btns
.btn-primary {
  @include button-variant(
    $primary,
    $primary,
    darken($primary, 10%),
    darken($primary, 10%),
    lighten($primary, 10%),
    lighten($primary, 10%)
  );
}

.btn-transparant {
  @include button-variant(
    $primary,
    $primary,
    darken($primary, 10%),
    darken($primary, 10%),
    lighten($primary, 10%),
    lighten($primary, 10%)
  );
}

.btn-white {
  @include button-variant(
    $white,
    darken($white, 7.5%),
    darken($white, 10%),
    lighten($white, 5%),
    lighten($white, 10%),
    darken($white, 30%)
  );
}

.btn-lg {
  text-transform: uppercase;
}

// Navbar
.logo {
  max-width: 11em;
  @media screen and (min-width: map-get($grid-breakpoints, "md")) {
    max-width: 13em;
  }
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    max-width: 13em;
  }
  @media screen and (min-width: map-get($grid-breakpoints, "xl")) {
    max-width: 20em;
  }
  @media screen and (min-width: map-get($grid-breakpoints, "xxl")) {
    max-width: 28em;
  }
}

.navbar {
  width: 100%;
  position: static;
  background-color: $primary;
}

.navbar-main {
  width: 100%;
  @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
    position: static;
    background-color: $primary;
  }
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    z-index: 10;
    overflow: hidden;
    position: absolute;
    background-color: $transparent;
  }
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 1em;
  padding-right: 1em;
  font-size: 0.85rem !important;
  @media (min-width: map-get($grid-breakpoints, "md")) {
    font-size: 0.95rem !important;
  }
  @media (min-width: map-get($grid-breakpoints, "xl")) {
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 1.05rem !important;
  }
  @media (min-width: map-get($grid-breakpoints, "xxl")) {
    font-size: 1.5rem !important;
    padding-right: 1.2rem;
    padding-left: 1.1rem;
  }
}

.navbar-toggler {
  background-color: $white;
  color: $white;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar a {
  color: $white;
}

.nav-tabs .nav-link {
  border: none;
  padding: 0.5rem 1.5rem;
}

.clients-logos {
  max-width: 100%;
  @media (min-width: map-get($grid-breakpoints, "md")) {
    max-height: 12em;
    max-width: 18em;
  }

  padding: 2em;

  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.clients-logos:hover {
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}

.diagonal-split-background-1,
.diagonal-split-background-2,
.diagonal-split-background-3,
.diagonal-split-background-4 {
  margin: 0;
  padding: 0;
}

.diagonal-split-background-1 {
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(280deg, #ffffff 55%, #f0f5f8 45%);
}

.diagonal-split-background-2 {
  background-color: #f0f5f8;
  background-image: -webkit-linear-gradient(260deg, #f0f5f8 60%, #ffffff 40%);
}

.diagonal-split-background-3 {
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(280deg, #ffffff 50%, #f0f5f8 50%);
}

.diagonal-split-background-4 {
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(280deg, #ffffff 50%, #f0f5f8 50%);
}

figure {
  -moz-box-shadow: 0 3px 15px #00000066;
  -webkit-box-shadow: 0 3px 15px #00000066;
  box-shadow: 0px 3px 15px #00000066;
}

figure {
}

.transparent-figure {
  background: $transparent;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

$previous-size: "null";
$previous-value: "null";
@each $size, $value in $grid-breakpoints {
  @if $previous-size != "null" {
    @media (min-width: $value) {
      .text-center-#{$previous-size} {
        text-align: center !important;
        margin-left: auto;
        margin-right: auto;
      }

      .text-right-#{$previous-size} {
        text-align: right !important;
      }

      .text-left-#{$previous-size} {
        text-align: left !important;
      }

      .text-justify-#{$previous-size} {
        text-align: justify !important;
      }
    }
  }
  $previous-size: $size;
  $previous-value: $value;
}

/* white circles */
.flickity-page-dots .dot {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: $primary;
  border: 2px solid $primary;
}
/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  background: $primary;
}

.services-list {
  background-color: $transparent;
  width: 100%;
}

.services-list > .list-group-item {
  background-color: $transparent;
  border-width: 0;
  padding: 0.2em;
}

.services-list > .list-group-item > .accordion-header {
  border-radius: 2em;
  background-color: $white;

  -moz-box-shadow: 0 5px 5px #00000033;
  -webkit-box-shadow: 0 5px 5px #00000033;
  box-shadow: 0 5px 5px #00000033;
}

.services-list > .list-group-item > .accordion-header::before {
  content: "";
  background: #a7abab;
  border: 0.3em solid #9ebdd6;

  width: 3em;
  height: 3em;
  margin-right: 0.5em;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.services-list
  > .list-group-item:nth-child(4n - 3)
  > .accordion-header::before {
  background: #0d4b8d;
  border: 0.3em solid #a7abad;
}

.services-list
  > .list-group-item:nth-child(4n - 2)
  > .accordion-header::before {
  background: #a7abad;
  border: 0.3em solid #a3bed8;
}

.services-list
  > .list-group-item:nth-child(4n - 1)
  > .accordion-header::before {
  background: #0d4b8d;
  border: 0.3em solid #a3bed8;
}

.services-list > .list-group-item:nth-child(4n) > .accordion-header::before {
  background: #a3bed8;
  border: 0.3em solid #a7abad;
}

.services-list > .list-group-item > .collapse {
}

.flickity-slider {
  display: flex;
  align-items: center;
}

textarea.form-control {
  min-height: 15em;
}

.list-disc {
  list-style: none;
  font-size: 1.1em;
  color: $black;
  font-weight: 400;
}

.list-disc li:before {
  content: "";
  background-image: url("../img/list-disc.png");
  display: block;
  margin-left: -20px;
  width: 10px;
  height: 10px;
  background-size: 10px;
  margin-top: 0.5em;
  position: relative;
  float: left;
}

.list-disc ul {
  list-style: none;
}

.list-disc ul li:before {
  content: "";
  background-image: url("../img/list-circle.png");
  display: block;
  margin-left: -20px;
  width: 10px;
  height: 10px;
  background-size: 10px;
  margin-top: 0.5em;
  position: relative;
  float: left;
}

.list-none .list-disc {
  list-style: none;
}

.list-none .list-disc li:before {
  content: "";
  background-image: url("../img/list-disc.png");
  display: block;
  margin-left: -20px;
  width: 10px;
  height: 10px;
  background-size: 10px;
  margin-top: 0.5em;
  position: relative;
  float: left;
}

.list-none .list-disc ul {
  list-style: none;
}

.list-none .list-disc ul li:before {
  content: "";
  background-image: url("../img/list-circle.png");
  display: block;
  margin-left: -20px;
  width: 10px;
  height: 10px;
  background-size: 10px;
  margin-top: 0.5em;
  position: relative;
  float: left;
}

.list-none {
  list-style-type: none;
  font-size: 1.1em;
  color: $black;

  font-weight: 400;
}

#topBtn {
  position: fixed;
  width: 10em;
  height: 10em;
  bottom: 10px;
  right: 5px;
  z-index: 50;
  font-size: 18px;
  border: none;
  outline: none;
  color: $blue;
  background-color: $transparent;
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.5s linear;
  text-transform: uppercase;
  font-size: 12px;
}

.topBtn-visible {
  opacity: 1 !important;
  transition: opacity 0.5s linear;
}

#topBtn:hover {
  background-color: $gray-200;
}

footer {
  position: relative;
  z-index: 100;
}
