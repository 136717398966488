//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

// Color
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #1a1a1a;

$dark: #1a1a1a;
$grey: #a7aaad;
$light-blue: #a3bed7;
$blue: #03498d;
$transparent: rgba(0, 0, 0, 0);

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;

$diagonal-background-1: #ffffff;
$diagonal-background-2: #f0f5f8;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "light-blue": $light-blue,
  "white": $white,
  "light-gray": $gray-200,
  "gray": $gray-600,
  "gray-dark": $gray-800
);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

.text-black-50 {
  color: rgba($black, 0.5) !important;
}
.text-white-50 {
  color: rgba($white, 0.5) !important;
}

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

// Border radius
$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.2rem;
$btn-border-radius: 0px;
$btn-border-radius-lg: 0px;
$btn-border-radius-sm: 0px;
// Fonts
$font-family-sans-serif: "Fira Sans", sans-serif;
$open-sans: "Open Sans", sans-serif;
// Secor circles
$sector-circle-border-width: 15px;
// Headings
$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;
$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$navbar-nav-link-padding-x: 2.5rem;
